/* eslint-disable react/no-unescaped-entities */
import { graphql } from 'gatsby'
import React from 'react'

import Layout from '../../components/layout'
import SEO from '../../components/seo'
import '../../less/cms.less'

const MerchantAgreement = () => {
  return (
    <Layout>
      <SEO title="Merchant Agreement" />
      <div className="cms-container">
        <h1 className="cms-container__title">Restaurants Terms &amp; Conditions</h1>
        <div className="cms-container__wrapper">
          <p>
            This agreement outlines additional terms and conditions agreed upon between the Restaurant and MealPal Ltd
            ("MealPal").
          </p>

          <h3>Restaurant&#39;s responsibility for quality and safety</h3>
          <p>
            The Restaurant warrants that the meals it supplies shall be of satisfactory quality and match their
            description.
          </p>
          <p>
            The Restaurant agrees accepts that is solely responsible for the quality and safety of all meals supplied to
            MealPal subscribers. It shall provide such warnings to MealPal subscribers as are appropriate required and
            necessary in accordance with best retail food industry practices laws and regulations relating to the meals
            and their ingredients including without limitation all necessary information relating to food allergies.
          </p>
          <p>
            The Restaurant will hold MealPal Limited and its affiliated companies and its and their directors officers
            and employees harmless and shall fully and effectively indemnify each of them from and against all and any
            costs claims liabilities and expenses received suffered or incurred whatsoever and howsoever arising by
            reason of the Restaurant providing meals to MealPal's subscribers.
          </p>

          <h3>Data Protection and Privacy</h3>
          <p>
            MealPal shall process any personal data provided by or on behalf of the Restaurant in accordance with the
            terms of the <a href="/uk/privacy-policy">MealPal Privacy Policy</a>.
          </p>

          <h3>Other Terms and Conditions</h3>
          <ul>
            <li>
              Either party can terminate the arrangements (without limiting its other rights or remedies), by giving
              written notice to the other party if
            </li>
            <ul>
              <li>
                the other party is in material breach of this agreement (& if such a breach can be fixed) fails to fix
                it within 20 days of being notified in writing to do so or
              </li>
              <li>
                if either party is insolvent, goes into an administration, provisional liquidation or any arrangement
                with its creditors (other than in relation to a solvent restructuring), or is being wound up.
              </li>
            </ul>
            <li>
              The Restaurant's liability under any matter indemnified in these terms shall survive and not be affected
              by termination of the arrangements and agreement between the parties.
            </li>
            <li>
              Display a visible MealPal sign with the QR code for all customers to scan in order to pick up their meal.
            </li>
            <li>
              The restaurant will be charged a 3.5% payment handling fee. This will be deducted from the weekly payment.
            </li>
            <li>
              Merchant agrees to provide accurate and complete banking details to MealPal. If the Merchant does not
              provide payment details for over one year, they agree to relinquish these funds to MealPal.
            </li>
            <li>
              MealPal will pay the restaurant for all meals ordered or all meals picked up via a successful QR code
              scan, whichever is greater.
            </li>
          </ul>
          <p>All terms and conditions are subject to change at any time at MealPal Limited's discretion.</p>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
export default MerchantAgreement
